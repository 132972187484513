@import 't4b-core-frontend/src/css/colors';

.app-color-text {
  color: $app-color-text;
}

.app-color-light {
  background-color: $app-color-light;
}

.app-color-dark {
  background-color: $app-color-dark;
}

.app-color-red {
  color: #e95050 !important;
}

.app-color-main {
  color: #2c405a;
}

.app-color-group {
  color: #1c3451;
}

$app-color-reset: #40a2b5;
$app-color-reset-dark: #397b8e;
