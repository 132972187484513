.modalBody {
  position: relative;
  width: 100%;
  border: 1px dashed #4B6687;
  background-color: #E5EEFF4D;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 1.5rem;
}

.warning {
  position: absolute;
  top: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.csvIcon {
  width: 3rem;
  height: 3rem;
}
