.button {
  color: white;
  border: none;
  background: transparent;
  margin-right: 1rem !important;
  transition: .3s;
  &:hover {
    color: rgb(200, 199, 199);
  }
  &:active {
    color: white;
  }
  &:focus {
    outline: none;
  }
}

.customBadge {
  position: absolute;
  top: 1.25rem;
  right: 3.25rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  font-size: 0; /* Hide any default text */
}
