.wrapper {
  z-index: 1000;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #3e5068;
  background-color: #2C405A !important;
}

.bottomItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.5rem;
  gap: 1.75rem;
  cursor: pointer;
}

.show {
  width: 285px;
}

.hidden {
  width: 70px;
}

.barContainer{
  height: calc(100vh - 180px);
}
