.downloadFilesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .fileContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .download {
            display: flex;
        }
    }
}