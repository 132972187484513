@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.container {
  padding: 4rem 6rem;
}

.pName {
  font-weight: 600;
  color: #6C757D;
  margin-bottom: 2rem;
}

.table {
  width: 100%;
  margin: 1.5rem 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tableHead {
  font-size: 12px;
  font-weight: normal;
  padding: 1rem;
  color: rgba(44, 64, 90, 0.65);
}

.sortable {
  cursor: pointer;
}

.tableRow {
  border: 1px solid #E8E8E8;
}

.tableCell {
  padding: 1rem;
}

.cellContent {
  min-width: 10rem;
}

