.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: #397B8E;
  color: #fff;
  &:hover {
    color: #f2f1f1;
  }
}
